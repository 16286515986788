<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate('')"
        >新增</el-button
      >
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输入商品名称"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.is_on"
        placeholder="状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="已下架" :value="0" />
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter">
        搜索
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="商品ID" width="95" align="center">
        <template slot-scope="scope">
          <!-- {{ scope.$index + 1 }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="商品名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="商品图片" min-width="110" align="center">
        <template slot-scope="scope">
          <el-image
            class="image-url-150"
            :src="scope.row.cover"
            lazy
            :preview-src-list="[scope.row.cover]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="参考价格" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.price }}
        </template>
      </el-table-column>
      <el-table-column label="已售" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.sold_num || 0 }}
        </template>
      </el-table-column>
      <el-table-column label="库存" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.total_stock }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="参考价" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.reference_price }}
        </template>
      </el-table-column>
      <el-table-column label="拼团价" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.group_price }}
        </template>
      </el-table-column>
      <el-table-column label="拼团人数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.group_num }}
        </template>
      </el-table-column>
      <el-table-column label="砍价最低价" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.bargain_min_price }}
        </template>
      </el-table-column>
      <el-table-column label="砍价上限" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.every_bargain_max_money }}
        </template>
      </el-table-column>
      <el-table-column label="权重" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.weight }}
        </template>
      </el-table-column>


      
      <!-- <el-table-column label="砍价人数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.bargain_num }}
        </template>
      </el-table-column> -->

      <el-table-column
        label="操作"
        width="280"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleCreate(scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="scope.row.is_on == 1"
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '下架')"
            >下架</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '上架')"
            >上架</el-button
          >
          <el-button
            size="mini"
            type="success"
            @click="participation(scope.row, 1)"
          >
            拼团管理
          </el-button>
          <el-button
            size="mini"
            type="warning"
            @click="participation(scope.row, 2)"
          >
            砍价管理
          </el-button>
          <el-button
            size="mini"
            type="primary"
            @click="weight(scope.row.id,scope.row.weight)"
          >
            权重设置
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        title: "",
        is_on: "",
        is_index_show: "",
      },
      btnLoading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    weight(id,val){
       this.$prompt( '请输入置顶权重','提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        //   inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          inputErrorMessage: '格式不正确',
          inputType:'Number',
          inputValue:val??''
          
        }).then(({ value }) => {
          
          request({
            url: "/api/backend/product/weightStore",
            method: "post",
            data: { id:id,weight: value },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });       
        });
    },  
    participation(row, type) {
      // let data= JSON.stringify(row)
      if (type == 1) {
        this.$router.push(`/product/participation_pt?data=${row.id}`);
      } else {
        this.$router.push(`/product/participation_kj?data=${row.id}`);
      }
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/product/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
        console.log(this.list);
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleCreate(row) {
      this.$router.push(`/product/list_edit?id=${row.id}`);
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该课程类型, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/product/upDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    //删除
    handleShow(val, text) {
      this.$confirm("此操作将课程类型" + text + ", 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/course/categoryIndexShow",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
